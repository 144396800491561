#loadingOverlay {
    position: absolute;
    background: none;
    width: 100vw;
    height: 100vh;
    height: -webkit-fill-available;

    background: black;
    color: white;
    z-index: 1;
}
