@keyframes rotateAnim {
    0% {
        transform: rotate(0deg) scale(1);
    }
    20% {
        transform: rotate(0deg) scale(1);
    }
    30% {
        transform: rotate(10deg) scale(1);
    }
    70% {
        transform: rotate(-100deg) scale(1.4);
    }
    80% {
        transform: rotate(-90deg) scale(1.3);
    }
    100% {
        transform: rotate(-90deg) scale(1.3);
    }
}

.mobile-orientation-overlay {
    width: 100vw;
    height: 100vh;
    height: -webkit-fill-available;
    position: absolute;
    top: 0;
    left: 0;
    background-color: black;
    z-index: 999;

    display: flex;
    justify-content: center;
    align-items: center;

    > .message {
        color: white;
        font-family: Poppins;
        font-size: 1.8rem;
        font-style: normal;
        font-weight: 400;
        line-height: 2.7rem;
        letter-spacing: 0.03em;
        text-align: center;
        text-transform: uppercase;

        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;

        .rotate-animation {
            width: 11rem;
            height: 11rem;
            margin: 5rem;
            animation: 1s ease-in-out 0s alternate infinite running rotateAnim;
            svg {
                width: 100%;
                height: 100%;
            }
        }
    }

    .mobile-universal-footer {
        position: absolute;
        bottom: 0;
        width: 100vw;
        height: 33.5rem;
        background-color: black;

        display: flex;
        color: white;
        > .divider {
            position: absolute;
            width: 51.8rem;
            height: 1px;
            background: white;
            top: 17rem;
            left: 12rem;
            transform: rotate(-28.74deg);
            transform-origin: center;
        }
        > .right {
            position: absolute;
            font-size: 1.8rem;
            text-align: right;
            text-transform: uppercase;
            bottom: 4.4rem;
            right: 5rem;
            width: 35.8rem;
        }
        > .left {
            position: absolute;
            top: 0;
            left: 5rem;
            width: 32.5rem;
            height: 16.6rem;
            display: flex;
            justify-content: space-between;

            > * {
                height: 100%;
            }

            > .left {
            }
            > .right {
                display: flex;
                justify-content: flex-end;
                flex-direction: column;
                .top {
                    font-family: Apparel;
                    font-size: 3.6rem;
                    font-style: normal;
                    font-weight: 900;
                    line-height: 4.7rem;
                    letter-spacing: 0.03em;
                    text-align: left;
                    margin-bottom: 1.7rem;
                }
                .bottom {
                    font-family: Poppins;
                    font-size: 1.4rem;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 2.1rem;
                    letter-spacing: 1.399999976158142px;
                    text-align: left;
                }
            }

            img {
                height: 100%;
            }
        }
    }
}
