#mouse-wrapper {
    pointer-events: none;
    position: absolute;
    width: 0;
    height: 0;
    opacity: 1;
    z-index: 2;
    mix-blend-mode: difference;

    .mouse-inner-extra {
        text-align: center;
        position: absolute;
        width: 0;
        height: 0;
        border-radius: 100%;
        background-color: black;
        transform: translate3d(-50%, -50%, 0);
        -webkit-transition: background-color 300ms linear;
        -ms-transition: background-color 300ms linear;
        transition: background-color 300ms linear;

        font-family: Apparel;
        font-style: normal;
        font-weight: normal;
        font-size: 2.4rem;
        line-height: 3.1rem;
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;

        > * {
            flex-shrink: 0;
        }

        .arrow {
            width: 5rem;
            height: 5rem;
            svg {
                width: 100%;
                height: 100%;
            }
        }
        .philz {
            img {
                width: 37.34rem;
                height: 49.787rem;
                box-shadow: 1.2rem 1.2rem 0 0rem white,
                    1.2rem 1.2rem 0 0.5rem black;
            }
        }
        .expand {
            width: 5.2rem;
            height: 5.2rem;
            svg {
                width: 100%;
                height: 100%;
            }
        }
        .landingSocial-text {
            position: relative;
            left: -10rem;
            color: white;
            font-weight: 300;
            font-family: Poppins;
            font-size: 1.4rem;
        }
        .aboutSocial-text {
            position: relative;
            top: -6.5rem;
            color: white;
            font-weight: 300;
            font-family: Poppins;
            font-size: 1.4rem;
        }
        .text {
            font-family: Poppins;
            font-style: normal;
            font-weight: 500;
            font-size: 1.8rem;
            line-height: 2.7rem;
            color: white;
            background-color: black;
            border-radius: 0.8rem;
            padding: 0.6rem 2.6rem;
            text-transform: uppercase;
        }
    }

    .mouse-inner {
        text-align: center;
        position: absolute;
        width: 0;
        height: 0;
        opacity: 0.3;
        border-radius: 100%;
        background-color: black;
        transform: translate3d(-50%, -50%, 0);
        -webkit-transition: background-color 300ms linear;
        -ms-transition: background-color 300ms linear;
        transition: background-color 300ms linear;

        font-family: Apparel;
        font-style: normal;
        font-weight: normal;
        font-size: 2.4rem;
        line-height: 3.1rem;
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        // overflow: hidden;
    }
}
