@font-face {
    font-family: "Apparel";
    src: url("./assets/fonts/Apparel/Apparel\ Black.ttf");
    font-weight: 900;
    font-style: normal;
}
@font-face {
    font-family: "Apparel";
    src: url("./assets/fonts/Apparel/Apparel\ Bold.ttf") format("truetype");
    font-weight: 700;
    font-style: normal;
}
@font-face {
    font-family: "Apparel";
    src: url("./assets/fonts/Apparel/Apparel\ Regular.ttf") format("truetype");
    font-weight: 400;
    font-style: normal;
}
@font-face {
    font-family: "Poppins";
    src: url("./assets/fonts/Poppins/Poppins-Light.ttf") format("truetype");
    font-weight: 300;
    font-style: normal;
}
@font-face {
    font-family: "Poppins";
    src: url("./assets/fonts/Poppins/Poppins-Regular.ttf") format("truetype");
    font-weight: 400;
    font-style: normal;
}
@font-face {
    font-family: "Poppins";
    src: url("./assets/fonts/Poppins/Poppins-Medium.ttf") format("truetype");
    font-weight: 500;
    font-style: normal;
}
@font-face {
    font-family: "Poppins";
    src: url("./assets/fonts/Poppins/Poppins-SemiBold.ttf") format("truetype");
    font-weight: 600;
    font-style: normal;
}
@font-face {
    font-family: "Poppins";
    src: url("./assets/fonts/Poppins/Poppins-Bold.ttf") format("truetype");
    font-weight: 700;
    font-style: normal;
}
@font-face {
    font-family: "Poppins";
    src: url("./assets/fonts/Poppins/Poppins-ExtraBold.ttf") format("truetype");
    font-weight: 800;
    font-style: normal;
}

html,
body,
#root {
    overflow: hidden;
    padding: 0;
    margin: 0;
    width: 100vw;
    height: 100vh;
    height: -webkit-fill-available;
    font-family: Apparel;
    font-weight: normal;
    font-size: 0.52083333333333333333vw;
    color: black;
    background-color: white;
}
#main.isMobile {
    font-size: 1.33333333333333vw;
}
