#main {
    position: absolute;
    width: 100vw;
    height: 100vh;
    height: -webkit-fill-available;
    z-index: 1;
    overflow: hidden;
    // overflow-y: scroll;
    // overflow-x: hidden;
    background-color: #ffffff;
}
.scrollhere {
    overflow-y: scroll;
    overflow-x: hidden;
    width: 100vw;
    height: 100vh;
    height: -webkit-fill-available;
}

.tools {
    .tools-item {
        width: 4.5rem;
        height: 4.5rem;
        svg {
            width: 100%;
            height: 100%;
        }
    }
}

a:link {
    color: #000;
}
/* visited link */
a:visited {
    color: #000;
}
/* mouse over link */
a:hover {
    color: #000;
}
/* selected link */
a:active {
    color: #000;
}
#navbar {
    &.mobile {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        height: 11.5rem;
        background-color: #1c1c1c;
        color: #ffffff;
        font-size: 2.4rem;

        #nav-dropdown {
        }
    }

    position: absolute;
    left: 18.4rem;
    right: 20rem;
    top: 6.5rem;
    display: flex;
    justify-content: space-between;
    pointer-events: none;
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 1.8rem;
    > * {
        pointer-events: all;
    }
    .left,
    .right {
        gap: 1.8rem;
        display: flex;
        align-items: center;
        margin: 0 9.6rem;
    }
    .nav_button {
        cursor: pointer;
        padding: 0.8rem 1.6rem;
        &.active {
            outline: solid 0.3rem black;
        }
    }

    #language_globe {
        height: 2.5rem;
        width: 2.5rem;
        cursor: pointer;
        svg {
            width: 100%;
            height: 100%;
        }
    }

    .navigation-transition-overlay {
        position: absolute;
        display: none;
        pointer-events: none;
        z-index: 30;
        &.active {
            display: block;
        }
        .circle-wrapper {
            position: absolute;

            .circle-inner {
                position: absolute;
                transform: translate3d(-50%, -50%, 0);
                width: 0rem;
                height: 0rem;
                border-radius: 100%;
                background-color: black;
            }
        }
    }
}

.universal-footer {
    position: relative;
    width: 192rem;
    height: 50rem;
    background: #000000;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: white;

    .fancy-line-wrapper {
        width: 22rem;
        position: relative;
        .fancy-line {
            position: absolute;
            width: 51.823rem;
            left: -12rem;
            top: -3rem;
            height: 0.2rem;
            background-color: white;

            transform: rotate(-28.74deg);
        }
    }

    .quote-n-socials {
        width: 35.822rem;
        height: 15rem;
        margin-right: 20rem;
        font-family: Poppins;
        font-style: normal;
        font-weight: normal;
        font-size: 1.8rem;
        line-height: 2.7rem;
        text-transform: uppercase;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        a {
            color: white;
        }

        .quote {
            width: 100%;
            height: 8.356rem;
        }

        .socials {
            display: flex;
            justify-content: space-between;
            width: 31rem;

            .item {
                width: 4.5rem;
                height: 4.5rem;
                cursor: pointer;
                svg {
                    width: 100%;
                    height: 100%;
                    path {
                        stroke: white;
                        strokewidth: 0.1rem;
                    }
                }
            }
        }
    }

    .sitemap {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        margin: 0 6rem;

        width: 17.1rem;
        height: 24rem;
        font-family: Poppins;
        font-style: normal;
        font-weight: 600;
        font-size: 2.4rem;
        color: white;
        .item-inner {
            cursor: pointer;
        }
    }

    .cool-shapes {
        width: 9rem;
        height: 25.836rem;
        position: relative;
        background-blend-mode: difference;
        background: #000000;
        > * {
            position: absolute;
            mix-blend-mode: difference;
            background: #ffffff;
        }
        .circle {
            border-radius: 50%;

            left: 2.61rem;
            top: 12.568rem;
            width: 6.4rem;
            height: 6.4rem;
        }
        .ring {
            width: 9rem;
            height: 9rem;
            left: 0rem;
            top: 7.151rem;
            background-color: rgba(0, 0, 0, 0);
            border: solid 0.1rem white;
        }
        .line1 {
            width: 0.16rem;
            height: 18.268rem;
            left: 1.545rem;
            top: 0rem;
        }
        .line2 {
            width: 0.959rem;
            height: 22.094rem;
            left: 3.463rem;
            top: 3.742rem;
        }
    }

    .signature {
        color: #ffffff;
        display: flex;
        justify-content: space-between;
        width: 32.481rem;
        height: 16.561rem;
        margin-left: 20rem;
        .left {
            width: 8.188rem;
            height: 100%;
            img {
                width: 100%;
                height: 100%;
            }
        }
        .right {
            width: 22.2rem;
            height: 100%;
            display: flex;
            justify-content: flex-end;
            flex-direction: column;
            .top {
                font-family: Apparel;
                font-style: normal;
                font-weight: 900;
                font-size: 3.6rem;
                line-height: 4.7rem;
                display: flex;
                align-items: center;
                margin-bottom: 1.6rem;
            }
            .bottom {
                font-family: Poppins;
                font-style: normal;
                font-weight: normal;
                font-size: 1.4rem;
                line-height: 2.1rem;
                letter-spacing: 0.14rem;
            }
        }
    }
}
